import { Col, DatePicker, Layout, Row, Select } from "antd";
import dayjs from "dayjs";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { DocumentSeries, DocumentStatus } from "../../../models/data/Document";
import { getLocalizedValue } from "../../../utilities/MultilingualHelper";
import {
  CmsCreateButton,
  CmsRetractLeftButton,
  CmsExpandRightButton,
  CmsRemoveFiltersButton,
} from "../../common/ButtonComponents";
import { CmsSelect, CmsFilter, CmsFormItem } from "../../common/FormComponents";
import { CmsContent, CmsPageHeader, CmsSearch, CmsTable } from "../../common/PageComponents";
import { DocumentWizard } from "./DocumentWizard/DocumentWizard";
import { Attribute, AttributeDataType } from "../../../models/templates/Attribute";
import { AttributeFilterSchema } from "../../templates/attributes/AttributeFilterSchema";
import { FilterOutlined, LoadingOutlined } from "@ant-design/icons";
import { DocumentTemplateEntity } from "../../../models/templates/DocumentTemplate";
import { getDocumentListConfig } from "../../../utilities/configs/DocumentListConfig";
import { KeyedQueryFilters, useDocumentFilter } from "../../../hooks/useDocumentFilter";
import { useNotification } from "../../../hooks/useNotification";
import { useCmsContext } from "../../../context/app/CmsContext";
import { useQueryDocumentTemplate } from "../../../queries/document-template/detail";
import { useQueryDocumentSeries } from "../../../queries/document-series/lists";
import { useQueryAttributes } from "../../../queries/attributes/lists";
import { useDeleteDocumentSerie } from "../../../queries/document-series/detail";
import { useQueryDocumentTypes } from "../../../queries/document-types/lists";

export const DocumentsList = () => {
  const { documentTemplateId } = useParams<DocumentTemplateEntity>();
  const context = useCmsContext();
  const { canDelete, canCreate, canUpdate, canView } = useAuthorization("document");
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();
  const history = useHistory();
  const {
    changeFilterPanel,
    saveFilters,
    restoreFilters,
    resetFilters,
    disableBefore,
    disabledAfter,
  } = useDocumentFilter();

  const [state, setState] = useState<{
    expandedTypes: string[];
    showNewDocumentWizard: boolean;
    filterPanelShow: boolean;
  }>({
    expandedTypes: [],
    showNewDocumentWizard: false,
    filterPanelShow: context?.documentSeriesFilterPanel ?? false,
  });
  const [queryFilter, setQueryFilter] = useState<KeyedQueryFilters>();

  useEffect(() => {
    const restored = restoreFilters();
    if (!!restored && restored?.query?.documentTemplateId === documentTemplateId) {
      setQueryFilter(restored);
    } else {
      resetFilters();
      setQueryFilter({ query: { documentTemplateId } });
    }
  }, [documentTemplateId]);

  const { data: documentTemplate, isLoading: isLoadingDocumentTemplate } =
    useQueryDocumentTemplate(documentTemplateId);
  const { data: documentTypes, isLoading: isLoadingDocumentTypes } = useQueryDocumentTypes();
  const { data: attributes, isLoading: isLoadingAttributes } = useQueryAttributes({
    documentTemplateId,
    filterable: true,
  });
  const { mutateAsync: deleteDocument, isLoading: isDeletingDocument } = useDeleteDocumentSerie();
  const { data: documents, isLoading: isLoadingDocuments } = useQueryDocumentSeries(queryFilter);

  const isLoading =
    isLoadingDocumentTypes || isLoadingDocuments || isLoadingDocumentTemplate || isDeletingDocument;

  const [filteredDocuments, setFilteredDocuments] = useState<DocumentSeries[]>(documents ?? []);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setFilteredDocuments(documents ?? []);
  }, [documents]);

  useEffect(() => {
    if (!!queryFilter) {
      saveFilters(queryFilter);
    }
  }, [queryFilter]);

  const changeDynamicFilter = (id: string, value?: any) => {
    if (!attributes) return;

    if (value !== null && value !== undefined) {
      return {
        ...queryFilter?.attributes,
        [id]: value,
      };
    }

    delete queryFilter?.attributes?.[id];

    return {
      ...queryFilter?.attributes,
    };
  };

  const search = (searchText: string) => {
    if (documents) {
      const search = searchText.toLowerCase().replace(" ", "_");
      const docs = documents.filter((document) => {
        const name = document.code && document.code.toLowerCase();
        return name && name.includes(search);
      });
      setSearchText(searchText);
      setFilteredDocuments(docs);
    }
  };

  useEffect(() => {
    context?.setDuplicateErrorMessage(t("errors:documentLinked"));
  }, [t]);

  const filterActive =
    (queryFilter?.attributes && Object.keys(queryFilter.attributes).length > 0) ||
    (queryFilter?.query &&
      Object.keys(queryFilter.query).filter((item) => item !== "documentTemplateId").length > 0);

  return (
    <React.Fragment>
      <CmsPageHeader
        title="Document series"
        extra={[
          filterActive && (
            <CmsRemoveFiltersButton
              key="resetFilter"
              onClick={() => {
                resetFilters();
                setQueryFilter({ query: { documentTemplateId } });
              }}
            />
          ),
          !context?.documentSeriesFilterPanel ? (
            <CmsExpandRightButton key="expand" onClick={() => changeFilterPanel(true)} />
          ) : (
            <CmsRetractLeftButton key="retract" onClick={() => changeFilterPanel(false)} />
          ),
          <CmsSearch key="search" value={searchText} onChange={search} />,
          canCreate && (
            <CmsCreateButton
              key="create"
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  showNewDocumentWizard: true,
                }))
              }
            />
          ),
        ]}
      />

      <Layout>
        <CmsContent>
          <Row>
            {context?.documentSeriesFilterPanel && (
              <Col flex={1}>
                <h1
                  style={{
                    textAlign: "right",
                    paddingRight: 20,
                    paddingTop: 15,
                    paddingBottom: 15,
                  }}
                >
                  Filters <FilterOutlined />
                </h1>
                <CmsFilter style={{ paddingRight: 20 }}>
                  <CmsSelect
                    id="documentTypeId"
                    label={t("properties:type")}
                    placeholder={t("properties:type")}
                    value={queryFilter?.query?.typeId}
                    onChange={(documentTypeId) =>
                      setQueryFilter((prevState) => ({
                        ...prevState,
                        query: { ...prevState?.query, typeId: documentTypeId },
                      }))
                    }
                  >
                    {documentTypes?.map((item, index) => (
                      <Select.Option key={index} value={item.id}>
                        {getLocalizedValue(item.caption) || item.name}
                      </Select.Option>
                    ))}
                  </CmsSelect>
                  <CmsSelect
                    id="documentStatus"
                    label={t("properties:status")}
                    placeholder={t("properties:status")}
                    value={queryFilter?.query?.documentStatus}
                    onChange={(e) =>
                      setQueryFilter((prevState) => ({
                        ...prevState,
                        query: { ...prevState?.query, documentStatus: e },
                      }))
                    }
                  >
                    {Object.values(DocumentStatus).map((item) => (
                      <Select.Option value={item}>
                        {t(`properties:documentStatus${item}`)}
                      </Select.Option>
                    ))}
                  </CmsSelect>
                  <CmsFormItem label={t("common:from")}>
                    <DatePicker
                      value={
                        queryFilter?.query?.modifiedAfter
                          ? dayjs(queryFilter?.query?.modifiedAfter)
                          : null
                      }
                      placeholder={t("properties:date")}
                      onChange={(_, datestring) =>
                        setQueryFilter((prevState) => ({
                          ...prevState,
                          query: { ...prevState?.query, modifiedAfter: datestring as string },
                        }))
                      }
                      disabledDate={(current) =>
                        disabledAfter(current, queryFilter?.query?.modifiedAfter)
                      }
                    />
                  </CmsFormItem>
                  <CmsFormItem label={t("common:until")}>
                    <DatePicker
                      value={
                        queryFilter?.query?.modifiedBefore
                          ? dayjs(queryFilter?.query?.modifiedBefore)
                          : null
                      }
                      placeholder={t("properties:date")}
                      onChange={(_, datestring) =>
                        setQueryFilter((prevState) => ({
                          ...prevState,
                          query: { ...prevState?.query, modifiedBefore: datestring as string },
                        }))
                      }
                      disabledDate={(current) =>
                        disableBefore(current, queryFilter?.query?.modifiedBefore)
                      }
                    />
                  </CmsFormItem>
                  {!isLoadingAttributes && !!attributes ? (
                    attributes.map((attr: Attribute) => (
                      <AttributeFilterSchema
                        key={attr.name}
                        attr={attr}
                        dataType={attr.dataType as AttributeDataType}
                        defaultValue={queryFilter?.attributes?.[attr.id as string]}
                        updateDefaultValue={(id, value) =>
                          setQueryFilter((prevState) => ({
                            ...prevState,
                            attributes: changeDynamicFilter(id, value),
                          }))
                        }
                      />
                    ))
                  ) : (
                    <div
                      style={{
                        position: "relative",
                        left: "50%",
                        marginTop: 100,
                      }}
                    >
                      <LoadingOutlined style={{ fontSize: 40 }} />
                    </div>
                  )}
                </CmsFilter>
              </Col>
            )}

            <Col flex={2}>
              <CmsTable
                loading={isLoading}
                dataSource={filteredDocuments}
                rowKey="id"
                columns={getDocumentListConfig(
                  isLoading,
                  documentTemplate?.name as string,
                  t,
                  documentTemplate?.attributeLinks,
                )}
                onEdit={
                  canUpdate || canView
                    ? (id) => history.push(`/document-series/${documentTemplateId}/${id}`)
                    : undefined
                }
                onDelete={canDelete ? async (id) => await deleteDocument(id) : undefined}
              />
            </Col>
          </Row>
        </CmsContent>
      </Layout>

      {state.showNewDocumentWizard && (
        <DocumentWizard
          onClose={async (newDocument) => {
            if (newDocument && newDocument.documentTemplateId === documentTemplateId) {
              notifySuccess(
                t("common:successSave", {
                  entity: t("common:document").toLocaleLowerCase(),
                }),
              );
            }
            setState((prevState) => ({
              ...prevState,
              showNewDocumentWizard: false,
            }));

            if (newDocument?.id) {
              window.location.replace(
                `/document-series/${newDocument.documentTemplateId}/${newDocument?.id}`,
              );
            }
          }}
        />
      )}
    </React.Fragment>
  );
};
