import { Radio, Select, DatePicker, InputNumber, Input } from "antd";
import { useTranslation } from "react-i18next";
import { IListSchema } from "../../../models/InputComponentData";
import { Attribute, AttributeDataType } from "../../../models/templates/Attribute";
import { getLocalizedValue } from "../../../utilities/MultilingualHelper";
import { CmsFormItem, CmsSelect } from "../../common/FormComponents";
import { capitalizeFirstLetter } from "../../../utilities/StringHelper";
import { CountryListFilter } from "./filters/CountryListFilter";
import { RegionListFilter } from "./filters/RegionListFilter";
import { ProvinceListFilter } from "./filters/ProvinceListFilter";
import { CurrencyListFilter } from "./filters/CurrencyListFilter";
import { OrganizationListFilter } from "./filters/OrganizationListFilter";

export const AttributeFilterSchema = (props: {
  attr?: Attribute;
  dataType: AttributeDataType;
  defaultValue: any;
  updateDefaultValue: (id: string, value: any) => void;
  customList?: any;
}) => {
  const { t } = useTranslation();

  const generateInputs = () => {
    switch (props.dataType) {
      case AttributeDataType.Number:
        return generateFilterNumber();
      case AttributeDataType.DateTime:
        return generateFilterDateTime();
      case AttributeDataType.List:
        return generateFilterList();
      case AttributeDataType.Country:
        return (
          <CountryListFilter
            attr={props.attr}
            defaultValue={props.defaultValue}
            updateDefaultValue={props.updateDefaultValue}
          />
        );
      case AttributeDataType.Province:
        return (
          <ProvinceListFilter
            attr={props.attr}
            defaultValue={props.defaultValue}
            updateDefaultValue={props.updateDefaultValue}
          />
        );
      case AttributeDataType.Region:
        return (
          <RegionListFilter
            attr={props.attr}
            defaultValue={props.defaultValue}
            updateDefaultValue={props.updateDefaultValue}
          />
        );
      case AttributeDataType.Currency:
        return (
          <CurrencyListFilter
            attr={props.attr}
            defaultValue={props.defaultValue}
            updateDefaultValue={props.updateDefaultValue}
          />
        );
      case AttributeDataType.Organization:
        return (
          <OrganizationListFilter
            attr={props.attr}
            defaultValue={props.defaultValue}
            updateDefaultValue={props.updateDefaultValue}
          />
        );
      case AttributeDataType.Text:
        return generateFilterText();
      case AttributeDataType.YesNo:
        return generateFilterYesNo();
      default:
        return null;
    }
  };

  const generateFilterYesNo = () => {
    return (
      <CmsFormItem
        label={capitalizeFirstLetter(
          getLocalizedValue(props.attr?.caption) || props.attr?.name || "",
        )}
      >
        <Radio.Group
          onChange={(e) =>
            props.attr?.id && props.updateDefaultValue(props.attr?.id, e.target.value)
          }
          value={props.defaultValue !== undefined ? props.defaultValue : undefined}
          buttonStyle="solid"
        >
          <Radio.Button value={undefined}>{"-"}</Radio.Button>
          <Radio.Button value={true}>{t("common:yes")}</Radio.Button>
          <Radio.Button value={false}>{t("common:no")}</Radio.Button>
        </Radio.Group>
      </CmsFormItem>
    );
  };

  const generateFilterList = () => {
    const listSchema = props.attr!.dataTypeSchema as IListSchema;
    return (
      <CmsSelect
        id="documentStatus"
        label={capitalizeFirstLetter(
          getLocalizedValue(props.attr?.caption) || props.attr?.name || "",
        )}
        placeholder={t("properties:makeSelection")}
        value={props.defaultValue !== undefined ? props.defaultValue : undefined}
        onChange={(e) => props.attr?.id && props.updateDefaultValue(props.attr?.id, e)}
      >
        {listSchema.items.map((x) => (
          <Select.Option key={x.code} value={x.code}>
            {getLocalizedValue(x.name) || x.code}
          </Select.Option>
        ))}
      </CmsSelect>
    );
  };

  const generateFilterDateTime = () => {
    return (
      <CmsFormItem
        label={capitalizeFirstLetter(
          getLocalizedValue(props.attr?.caption) || props.attr?.name || "",
        )}
      >
        <DatePicker
          value={props.defaultValue !== undefined ? props.defaultValue : undefined}
          placeholder={t("properties:date")}
          onChange={(e) => props.attr?.id && props.updateDefaultValue(props.attr?.id, e)}
        />
      </CmsFormItem>
    );
  };

  const generateFilterNumber = () => {
    return (
      <CmsFormItem
        label={capitalizeFirstLetter(
          getLocalizedValue(props.attr?.caption) || props.attr?.name || "",
        )}
      >
        <InputNumber
          maxLength={10}
          value={props.defaultValue}
          onChange={(e) => props.attr?.id && props.updateDefaultValue(props.attr?.id, e)}
          readOnly={false}
          disabled={false}
        />
      </CmsFormItem>
    );
  };

  const generateFilterText = () => {
    return (
      <CmsFormItem
        label={capitalizeFirstLetter(
          getLocalizedValue(props.attr?.caption) || props.attr?.name || "",
        )}
      >
        <Input
          value={props.defaultValue !== undefined ? props.defaultValue : undefined}
          allowClear
          onChange={(e) =>
            props.attr?.id &&
            props.updateDefaultValue(
              props.attr.id,
              e.target.value !== "" ? e.target.value : undefined,
            )
          }
          readOnly={false}
          disabled={false}
        />
      </CmsFormItem>
    );
  };

  return generateInputs();
};
